import * as libphonenumber from "libphonenumber-js/max";

export function formatPhone(phone) {
  const { parsePhoneNumber } = libphonenumber;

  try {
    const phoneNumber = parsePhoneNumber(phone, "UA");

    return phoneNumber.number;
  } catch (e) {
    return phone;
  }
}

export function phoneCheck(phone) {
  const { parsePhoneNumber } = libphonenumber;

  try {
    const number = parsePhoneNumber(phone, "UA");
    return number.isValid();
  } catch (e) {
    return false;
  }
}
